import React, { Fragment, useState } from 'react';

import Link from 'next/link';

import Intercom from '@intercom/messenger-js-sdk';

import { useSession } from 'next-auth/react';

import { BsTicketPerforated } from 'react-icons/bs';
import { FaGift, FaRegCompass, FaStar } from 'react-icons/fa';

import { GiMoneyStack } from 'react-icons/gi';

import { IoTrophyOutline, IoChatbubbleEllipses } from 'react-icons/io5';
import { RiVipCrown2Line } from 'react-icons/ri';

import { useAuthStore } from '@/stores/userStore';

import {
  Sheet,
  SheetContent,
  SheetTrigger,
  SheetClose,
} from '@/components/ui/sheet';

import LinkItem from '../items/LinkItem';
import {
  Accordion, AccordionContent, AccordionItem, AccordionTrigger,
} from '../ui/accordion';
import { ScrollArea } from '../ui/scroll-area';
import { Separator } from '../ui/separator';
import {
  Tabs, TabsContent, TabsList, TabsTrigger,
} from '../ui/tabs';

declare global {
  interface Window {
    zE?: any;
  }
}

const arrOptions = [
  {
    title: 'Baloncesto',
    icon: 'basketball',
    type: 'sport',
    href: '/deportes?leagueLink=championship/2980',
    list: [
      {
        link: '/deportes?leagueLink=championship/2980',
        name: 'NBA',
        key: 11,
      },
      {
        link: '/deportes?leagueLink=championship/2980',
        name: 'WNBA',
        key: 12,
      },
      {
        link: '/deportes?leagueLink=championship/2980',
        name: 'Euroliga',
        key: 13,
      },
      {
        link: '/deportes?leagueLink=championship/2980',
        name: 'JJOO',
        key: 14,
      },
      {
        link: '/deportes?leagueLink=championship/2980',
        name: 'Liga ACB',
        key: 15,
      },
    ],
    key: 'Baloncesto',
  },
  {
    title: 'Beisbol',
    icon: 'tennil',
    type: 'sport',
    href: '/deportes?leagueLink=championship/3286',
    list: [
      {
        link: '/deportes?leagueLink=championship/3232',
        name: 'NHL',
        key: 21,
      },
      {
        link: '/deportes?leagueLink=championship/3232',
        name: 'AHL',
        key: 22,
      },
      {
        link: '/deportes?leagueLink=championship/3232',
        name: 'KHL',
        key: 23,
      },
      {
        link: '/deportes?leagueLink=championship/3232',
        name: 'Campeonato Mundial',
        key: 24,
      },
      {
        link: '/deportes?leagueLink=championship/3232',
        name: 'Superliga',
        key: 25,
      },
    ],
    key: 'Beisbol',
  },
  {
    title: 'Esports',
    icon: 'joystick',
    type: 'sport',
    href: '/deportes#/esports',
    list: [
      {
        link: '/deportes?leagueLink=category/542',
        name: 'Wimbledon (M)',
        key: 31,
      },
      {
        link: '/deportes?leagueLink=category/542',
        name: 'Wimbledon (F)',
        key: 32,
      },
      {
        link: '/deportes?leagueLink=category/542',
        name: 'Australian Open(M)',
        key: 33,
      },
      {
        link: '/deportes?leagueLink=category/542',
        name: 'US Open(F)',
        key: 34,
      },
      {
        link: '/deportes?leagueLink=category/542',
        name: 'US Open(M)',
        key: 35,
      },
    ],
    key: 'Tenis',
  },
  {
    title: 'Arcade',
    href: '/casino?category=casual',
    icon: 'joystick',
    type: 'casino',
    key: 'Arcade',
  },
  {
    title: 'Slots',
    href: '/casino?category=slots',
    icon: 'slot',
    type: 'casino',
    key: 'Slots',
  },
  {
    title: 'Loteria',
    href: '/casino?category=lottery',
    icon: <GiMoneyStack className="text-2xl self-center" />,
    type: 'casino',
    key: 'Loteria',
  },
  {
    title: 'Juegos de Cartas',
    href: '/casino?category=card',
    icon: 'cards',
    type: 'casino',
    key: 'Juegos de Cartas',
  },
  {
    title: 'Juegos Crash',
    href: '/casino?category=crash',
    icon: 'dart',
    type: 'casino',
    key: 'Juegos Crash',
  },
];

const futbolOption = {
  title: 'Futbol',
  icon: 'ball',
  type: 'sport',
  list: [
    {
      link: '/deportes?leagueLink=championship/16808',
      name: 'Champions League',
      icon: 'icon-logo_championsleague',
      key: 10,
    },
    {
      link: '/deportes?leagueLink=championship/2936',
      name: 'Premier League',
      icon: 'icon-logo_premierleague1',
      key: 20,
    },
    {
      link: '/deportes?leagueLink=championship/2941',
      name: 'La Liga',
      icon: 'icon-logo_laliga_sm',
      key: 30,
    },
    {
      link: '/deportes?leagueLink=championship/16809',
      name: 'EuroLeague',
      icon: 'icon-UEFA-Europa-League-_1_',
      key: 40,
    },
    {
      link: '/deportes?leagueLink=championship/2950',
      name: 'Bundesliga',
      icon: 'icon-logo_bundesliga',
      key: 50,
    },
  ],
  key: 'Futbol',
};

export default function DiscoverSheet() {
  const { account } = useAuthStore();
  const { data: session } = useSession();
  const [isSheetOpen, setIsSheetOpen] = useState(false);

  const handleSheetOpen = () => {
    setIsSheetOpen(true);
  };

  const handleSupportClick = async () => {
    try {
      if (session?.user?.data && account) {
        // Authenticated user
        const hash = await fetch('/api/integrations/intercom').then((res) => { return res.json(); });

        Intercom({
          app_id: 'l2erqbk4',
          user_id: account.topup_id,
          name: account.name,
          email: account.email,
          company: 'Hondubet',
          created_at: account.createdAt ? Math.floor(new Date(account.createdAt).getTime() / 1000) : undefined,
          user_hash: hash,
          hide_default_launcher: true,

        });
      } else {
      // Unauthenticated user
        Intercom({
          app_id: 'l2erqbk4',
          company: 'Hondubet',
          hide_default_launcher: true,
        });
      }
      if (typeof window !== 'undefined' && window.Intercom) {
        window.Intercom('show'); // Opens the Intercom Messenger
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error initializing Intercom:', error);
    }
  };

  return (
    <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
      <SheetTrigger asChild>
        <div className="flex flex-col items-center active:text-sivarbet-primary focus:text-sivarbet-primary" onClick={handleSheetOpen}>
          <FaRegCompass className="text-xl" style={{ color: 'rgb(149, 251, 63)' }} />
          <span className="text-xs mt-1" style={{ color: 'rgb(149, 251, 63)' }}>Descubre</span>
        </div>
      </SheetTrigger>
      <SheetContent side="left" className="flex flex-col items-stretch justify-center w-full bg-sivarbet-secondary-bg border-none h-[calc(100vh-4rem)] place-self-auto">
        <ScrollArea>
          <div className="h-full relative">
            <Tabs defaultValue="sport">
              <TabsList
                className="w-full bg-sivarbet-background rounded-xl text-sivarbet-text py-7 mt-2"
              >
                <TabsTrigger
                  className="flex-center-full ring-offset-deepskyblue-200 data-[state=active]:bg-sivarbet-primary data-[state=active]:rounded-lg"
                  value="sport"
                >
                  <span className="text-lg mx-1 fill-white icon icon-ball" />
                  <span className="text-lg">Deportes</span>
                </TabsTrigger>
                <TabsTrigger
                  className="flex-center-full ring-offset-deepskyblue-200 data-[state=active]:bg-sivarbet-primary data-[state=active]:rounded-lg"
                  value="casino"
                >
                  <span className="text-lg mx-1 fill-white icon icon-cards" />
                  <span className="text-lg">Casino</span>
                </TabsTrigger>
              </TabsList>
              <div
                className="flex-col-bg"
              >
                {' '}
                <LinkItem
                  key={1}
                  link={{
                    title: 'Eventos en vivo',
                    href: '/deportes#/live',
                    icon: 'live',
                    type: 'general',
                  }}
                  isCollapsed={false}
                  withSheetClose
                />
                <LinkItem
                  key={2}
                  link={{
                    title: 'Torneos',
                    href: '/torneos',
                    icon: <IoTrophyOutline className="self-center h-4 w-4 text-xl" />,
                    type: 'general',
                  }}
                  isCollapsed={false}
                  withSheetClose
                />
                <LinkItem
                  key={3}
                  link={{
                    title: 'Promociones',
                    href: '/promotions',
                    icon: <FaGift className="self-center h-5 w-5 text-xl" style={{ color: 'rgb(149, 251, 63)' }} />,
                    type: 'general',
                  }}
                  isCollapsed={false}
                  withSheetClose
                />
                <LinkItem
                  key={4}
                  link={{
                    title: 'VIP',
                    href: '/vip',
                    icon: <RiVipCrown2Line className="self-center h-5 w-5 text-xl" />,
                    type: 'general',
                  }}
                  isCollapsed={false}
                  withSheetClose
                />
                <LinkItem
                  key={5}
                  link={{
                    title: 'Jackpots',
                    href: '/jackpots',
                    icon: 'slot',
                    type: 'general',
                  }}
                  isCollapsed={false}
                  withSheetClose
                />
                <LinkItem
                  key={6}
                  link={{
                    title: 'Sorteos',
                    href: '/sorteos',
                    icon: <BsTicketPerforated className="self-center h-4 w-4 text-xl" />,
                    type: 'general',
                  }}
                  isCollapsed={false}
                  withSheetClose
                />
                <LinkItem
                  key={6}
                  link={{
                    title: 'Favoritos',
                    href: '/favoritos',
                    icon: <FaStar className="self-center h-5 w-5 text-xl" />,
                    type: 'general',
                  }}
                  isCollapsed={false}
                  withSheetClose
                />
              </div>
              <TabsContent
                value="sport"
                className="flex flex-col bg-sivarbet-background rounded-xl"
              >
                <>
                  <span className="text-lg-medium">
                    Deportes
                  </span>
                  <Separator className="bg-sivarbet-border border-sivarbet-border border-[1px]" />
                  <Accordion type="single" collapsible className="w-full z-10">
                    <AccordionItem
                      value={futbolOption.title}
                      className="border-none"
                    >
                      <AccordionTrigger className="flex justify-between px-4 hover:no-underline">
                        <div className="flex items-center justify-center gap-4">
                          <span className="fill-white icon icon-ball text-base" />
                          <span className="text-base">{futbolOption.title}</span>
                        </div>
                      </AccordionTrigger>
                      <AccordionContent className="p-1 shadow-md bg-sivarbet-border rounded-b-xl">
                        <ul className="px-4 py-2 space-y-2">
                          {futbolOption.list
                              && futbolOption.list.map((item, index) => {
                                const isLastItem = index === futbolOption.list.length - 1;
                                return (
                                  <Fragment key={item.key}>
                                    <li
                                      key={item.key}
                                      className="hover:bg-sivarbet-primary rounded-xl transition duration-2000 flex"
                                    >
                                      <Link
                                        href={item.link}
                                        className="py-2 px-3 text-sivarbet-secondaryext flex items-center gap-2 hover:text-sivarbet-border transition duration-200 rounded-md text-xs"
                                      >
                                        <span className={`icon ${item.icon} text-lg`} />
                                        {item.name}
                                      </Link>
                                    </li>
                                    {!isLastItem && (
                                      <Separator className="bg-sivarbet-background" />
                                    )}
                                  </Fragment>
                                );
                              })}
                        </ul>
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                  <div className="flex flex-col mb-2 gap-2">
                    {arrOptions
                      .filter((link) => { return link.type === 'sport'; })
                      .map((link) => {
                        return (
                          <LinkItem
                            link={link}
                            key={link.key}
                            isCollapsed={false}
                            withSheetClose
                          />
                        );
                      })}
                  </div>
                </>
              </TabsContent>
              <TabsContent
                value="casino"
                className="flex flex-col bg-sivarbet-background rounded-xl"
              >
                <>
                  <span className="text-lg-medium">
                    Casino
                  </span>
                  <Separator className="bg-sivarbet-border border-sivarbet-border border-[1px]" />
                  <div className="flex flex-col mb-2 gap-2">
                    {arrOptions
                      .filter((link) => { return link.type === 'casino'; })
                      .map((link) => {
                        return (
                          <LinkItem
                            link={link}
                            key={link.key}
                            isCollapsed={false}
                            withSheetClose
                          />
                        );
                      })}
                  </div>
                </>
              </TabsContent>
              {/* <div className="flex flex-col mb-2 gap-2">
                  <LinkItem
                    link={{
                      title: 'Referidos',
                      href: '/referidos',
                      icon: <FaPeopleRobbery className="self-center h-5 w-5 text-xl" />,
                      type: 'general',
                    }}
                    isCollapsed={false}
                    withSheetClose
                  />
                </div> */}
              <div
                className="flex-col-bg"
              >
                <SheetClose asChild>
                  <button
                    type="button"
                    className="flex items-center gap-2 py-1 px-3 hover:bg-sivarbet-primary rounded-xl transition duration-200"
                    onClick={handleSupportClick}
                  >
                    <IoChatbubbleEllipses className="text-lg text-sivarbet-background" style={{ color: 'rgb(149, 251, 63)' }} />
                    <span className="text-base">Soporte</span>
                  </button>
                </SheetClose>
              </div>
            </Tabs>
          </div>
        </ScrollArea>
      </SheetContent>
    </Sheet>
  );
}
